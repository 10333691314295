import React from "react";
import Layout from "../components/layout";
import Section from "../components/Section";
import SEO from "../components/seo";
import PrivacyPolicy from "../components/PrivacyPolicy";

const PrivacyPage = () => {
  return (
    <Layout>
      <SEO title="Privacy Policy" card="generic" />
      <Section>
        <PrivacyPolicy
          endpoint="https://www.iubenda.com/api/privacy-policy/78557687/no-markup"
          url="https://www.iubenda.com/privacy-policy/78557687"
        />
      </Section>
    </Layout>
  );
};

export default PrivacyPage;
